/* 

table.editForm_table {

    border-collapse: separate;

    width: 80%;

    border: 1px solid lightgrey;

    margin-left: auto;

    margin-right: auto;

    border-spacing: 10px 1em;

    margin-top: 20px;

}




th.editForm_table {

    padding-right: 30px;

    background-color: lightgray;

    border: 1px solid grey;

    text-align: center; 

}

td.td_value {

    text-align: left;

}

tr.editForm_table{

    border-bottom: 1px solid lightgrey;

}

td.td_key {

    text-wrap: nowrap;

    text-align: center;

    float: right;

    margin-right: 20px;

}




 */

table.editForm_table {
  border: none;

  float: left;

  width: 88%;
}

th.editForm_table {
  padding-right: 30px;

  background-color: lightgray;

  border: 1px solid grey;

  text-align: center;
}

td.td_value {
  border: none;

  display: flex;

  float: left;

  padding: 14px;
  align-items: center;
}

td.td_value_left {
  padding-left: 0px;
}

tr.editForm_table {
  border-bottom: 1px solid lightgrey;
}

td.td_row_rightvalue {
  border: none;
  display: flex;
  float: right;
  padding-left: 0px;
}

table .td.td_key {
  border: none;

  display: flex;

  float: left;

  width: 200px;

  vertical-align: middle;

  text-align: left;

  white-space: nowrap;

  padding: 5px;
}

.blur-background {
  filter: blur(5px);
  transition: filter 0.3s;
}

/* tr.editForm_table-row-checkbox {
    align-items: center;
    margin-bottom: 15px;
    border: none;
    display: flex;
    align-content: flex-start;
} */

tr.editForm_table-row {
  align-items: center;
  margin-bottom: 15px;
  border: none;
  display: flex;
  align-content: flex-start;
}

.SearchBarDiv {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 10px;
  background-color: #f8f9fa;
  line-height: 0%
}

.SearchButtonDiv {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.verint-regular-label {
  margin-right: 10px;
}

.SearchBarDiv .form-control {
  display: inline-block;
  vertical-align: middle;
}

.searchButton,
.clearButton {
  margin-left: 10px;
}

#SearchBar_label {
  padding-left: 20px;
}

#myRequests_label {
  padding-left: 20px;
}

.verint-horizontalList .MuiPaper-root .MuiDialogContent-root {
  overflow-x: hidden;
}

.verint-horizontalList .moveButtonsContainer {
  padding: 0px !important;
}

.verint-popup .MuiDialogActions-root {
  padding: 20px 25px 38px 9px !important;
}


/* Spin animation for loading icon in Server Power feature */
.icon-active {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Bluring the ServerNames and Disable interactions */
.blurServerNames {
  filter: blur(0.8px);
  pointer-events: none;

}

.serverNames {
  margin-left: 5%;
}

.comboboxv2 ul {
  max-height: 11.375rem;
  overflow: auto;
}

.comboboxv2 li {
  display: flex !important;
}

/* CSS adjusting for TreeView expanding icons */
.MuiIcon-root {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: flex-end !important;
  overflow: visible !important;
}

.myRequests {
  display: contents;
}

/* Search Bar Styles for tablets and mobile devices */
@media (max-width: 1068px) {
  .SearchBarDiv {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .SearchBarDiv label {
    margin-bottom: 5px;
  }

  .SearchBarDiv .form-control {
    width: 100%;
    margin-bottom: 10px;
  }

  .SearchButtonDiv {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }


  .searchButton,
  .clearButton {
    margin-left: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  #SearchBar_label,
  #myRequests_label {
    padding-left: 0;
  }

  .myRequests {
    display: flex;
    align-items: center;
  }
}

/* Tables Styles for tablets and mobile devices */
@media (max-width: 1028px) {

  .verint-table {
    width: 500%;
    overflow: auto !important;
  }

  .smallScreen {
    width: 100% !important;
    overflow: auto !important;
  }

  .verint-popup .dialogContent .mainPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
  }

  .verint-horizontalList .MuiPaper-root {

    overflow: hidden;
  }


}

@media (max-height: 530px) {
  .verint-table {
    width: 500%;
    overflow: auto !important;
  }

  .smallScreen {
    width: 100% !important;
    overflow: auto !important;
  }

  .verint-popup .dialogContent .mainPanel {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
  }

  .verint-horizontalList .MuiPaper-root {

    overflow: hidden;
  }

}